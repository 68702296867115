@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

:root {
  --primary: #1a697f;
  --primary-contrast: #ffffff;
  --accent: #fc9f5b;
  --accent-contrast: #ffffff;
  --accent-tonal: #ffdbc4;
  --accent-tonal-contrast: #000000;
  --warn: #f44336;
  --warn-contrast: #ffffff;
  --master: #62c890;
  --master-contrast: #000000;
  --master-tonal: #adf2c6;
  --master-tonal-contrast: #002111;

  //image cropper
  --cropper-outline-color: rgba(255, 255, 255, 0.3);
  --cropper-overlay-color: #fefcf4;
}

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

// GENERATE THEME FROM HEX HERE: http://mcg.mbitson.com/
$primary: (
  50: #e4edf0,
  100: #bad2d9,
  200: #8db4bf,
  300: #5f96a5,
  400: #3c8092,
  500: #1a697f,
  600: #176177,
  700: #13566c,
  800: #0f4c62,
  900: #083b4f,
  A100: #84d7ff,
  A200: #51c6ff,
  A400: #1eb5ff,
  A700: #04adff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$accent: (
  50: #fff3eb,
  100: #fee2ce,
  200: #fecfad,
  300: #fdbc8c,
  400: #fcad74,
  500: #fc9f5b,
  600: #fc9753,
  700: #fb8d49,
  800: #fb8340,
  900: #fa722f,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffe2d5,
  A700: #ffd1bc,
  contrast: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$primary: mat.define-palette($primary);
$accent: mat.define-palette($accent, 500, 400, 700);
// The warn palette is optional (defaults to red).
$warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$theme: mat.define-light-theme($primary, $accent, $warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($theme);
